// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

// document.addEventListener("DOMContentLoaded", function(event) {
//   document.getElementById('#enter').click(function() {
//     var elmnt = document.getElementById("#body");
//     elmnt.scrollIntoView();
//   });
// })

window.mobile = function() {
  return window.innerWidth < 500
  // return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};


document.addEventListener("DOMContentLoaded", function() {
  let homeVideoMobile = document.getElementsByClassName('home__video__mobile')[0];
  let homeVideoDesktop = document.getElementsByClassName('home__video__desktop')[0];

  if(window.mobile()) {
    // IF MOBILE
    homeVideoDesktop.remove();
  }else{
    // IF DESKTOP
    homeVideoMobile.remove();
  }
  // setTimeout(function() {
  //   document.getElementsByClassName('_1yY9NJj-G0')[0].innerHTML = "Vallonier";
  // }, 3000)
});